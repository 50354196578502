export default {
  props: {
  },
  computed: {
    listByCode () {
      return this.$store.state.attribute.list_by_code
    },
    mappedAttributes () {
      return this.$store.state.attributes.mappedAttributes
    }
  },
  methods: {
    addAttributeToMap (type) {
      let attributeOptions = {}
      for (let option of this.listByCode[type].options) {
        attributeOptions[option.value] = option.label
      }
      this.$store.dispatch('attributes/callAttributesMapSetter', { type: type, attributeOptions: attributeOptions })
    },
    getLabelArraysforValues (type, value) {
      if (!value) return null
      if (this.listByCode && this.listByCode[type]) {
        let label
        label = this.listByCode[type].options.filter((option) => {
          for (let element of value) {
            if (option.value === String(element)) {
              return option.value === String(element)
            }
          }
        })
        if (label && label.length) {
          return label.map(el => {
            return el.label
          }).join()
        } else {
          return null
        }
      }
    },
    getLabelforValue (type, value) {
      if (!(this.listByCode && this.listByCode[type])) return null
      // add mapped attribute to store if not added before
      if (!Object.keys(this.mappedAttributes).includes(type)) {
        this.addAttributeToMap(type)
      }
      return this.mappedAttributes[type][value]
    }
  }
}
