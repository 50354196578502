<template>
  <div class="bg-cl-orange custom-border" :class="{'bg-cl-gray' : isProductDisabled}">
    <button-full
      @click.native="!isProductDisabled ? addToCart(product) : emitClick()"
      data-testid="addToCart"
      :class="[[isOverCompare ? 'col-xs-3' : 'col-xs-9'], [isProductDisabled ? 'disabled' : ''], {'bg-cl-gray': isAddingToCart}]"
      class="add-cart-btn relative"
    >
      <i class="icon-buy icon-size" />
      <p v-show="toCartText" class="m0 pl10">
        {{ $t('Do koszyka') }}
      </p>
      <div v-show="isAddingToCart" class="custom-loader">
        <div /><div /><div /><div />
      </div>
    </button-full>
    <button-full
      @mouseover.native="overWishlist()"
      @mouseleave.native="leaveWishlist()"
      @click.native="isOnWishlist ? removeProductFromWhishList(product) : addProductToWhishlist(product)"
      data-testid="addToCart"
      :class="isOverCompare ? 'col-xs-9' : 'col-xs-3'"
      class="add-compare-btn"
    >
      <p v-show="compareText" class="m0 pr10 cl-white" :class="{'cl-orange' : isOverCompare}">
        <template v-if="!isOnWishlist">
          <span>{{ $t('DO SCHOWKA') }}</span>
        </template>
        <template v-else>
          <span>{{ $t('USUŃ ZE SCHOWKA') }}</span>
        </template>
      </p>
      <i class="icon-schowek icon-size" />
    </button-full>
  </div>
</template>

<script>
import { formatProductMessages } from '@vue-storefront/core/filters/product-messages'
import { notifications } from '@vue-storefront/core/modules/cart/helpers'
import focusClean from 'theme/components/theme/directives/focusClean'
import ButtonFull from 'theme/components/theme/ButtonFull.vue'
import { mapGetters } from 'vuex'
import { IsOnWishlist } from 'src/modules/vsf-magento-wishlist/components/IsOnWishlist'
import { AddToWishlist } from 'src/modules/vsf-magento-wishlist/components/AddToWishlist'
import { RemoveFromWishlist } from 'src/modules/vsf-magento-wishlist/components/RemoveFromWishlist'
import i18n from '@vue-storefront/i18n'
import { formatProductLink } from '@vue-storefront/core/modules/url/helpers'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import CurrentPage from 'theme/mixins/currentPage'

export default {
  mixins: [CurrentPage, IsOnWishlist, AddToWishlist, RemoveFromWishlist],
  directives: { focusClean },
  components: { ButtonFull },
  props: {
    product: {
      required: true,
      type: Object
    },
    disabled: {
      type: Boolean,
      default: false
    },
    productLink: {
      type: String,
      default: '',
      required: false
    }
  },
  data () {
    return {
      toCartText: true,
      compareText: false,
      isOverCompare: false
    }
  },
  methods: {
    productLinkPush (product) {
      return formatProductLink(product, currentStoreView().storeCode)
    },
    addProductToWhishlist (product) {
      // this.$store.dispatch('notification/spawnNotification', {
      //   type: 'success',
      //   message: `Produkt ${product.name} został dodany do schowka!`,
      //   action1: { label: i18n.t('OK') }
      // }, { root: true })
      this.addToWishlist(product)
    },
    removeProductFromWhishList (product) {
      // this.$store.dispatch('notification/spawnNotification', {
      //   type: 'success',
      //   message: `Produkt ${product.name} został usunięty ze schowka!`,
      //   action1: { label: i18n.t('OK') }
      // }, { root: true })
      this.removeFromWishlist(product)
    },
    overWishlist () {
      this.toCartText = false
      this.compareText = true
      this.isOverCompare = true
    },
    leaveWishlist () {
      this.toCartText = true
      this.compareText = false
      this.isOverCompare = false
    },
    onAfterRemovedVariant () {
      this.$forceUpdate()
    },
    emitClick () {
      console.log('asd')
      this.$emit('click-add-to-cart')
    },
    async addToCart (product) {
      try {
        const diffLog = await this.$store.dispatch('cart/addItem', { productToAdd: product })
        if (this.isComparePage && diffLog.clientNotifications.length === 0) {
          this.$router.push(this.productLinkPush(product))
        }
        diffLog.clientNotifications.forEach(notificationData => {
          this.notifyUser(notificationData)
        })
      } catch (message) {
        this.notifyUser(notifications.createNotification({ type: 'error', message }))
      }
    },
    notifyUser (notificationData) {
      this.$store.dispatch('notification/spawnNotification', notificationData, { root: true })
      this.$store.commit('ui/setAddToCart', false)
    }
  },
  computed: {
    ...mapGetters({
      isAddingToCart: 'cart/getIsAdding'
    }),
    isProductDisabled () {
      return this.disabled || formatProductMessages(this.product.errors) !== '' || this.isAddingToCart
    }
  },
  beforeMount () {
    this.$bus.$on('product-after-removevariant', this.onAfterRemovedVariant)
  },
  beforeDestroy () {
    this.$bus.$off('product-after-removevariant')
  }
}
</script>

<style lang="scss" scoped>
.icon-size {
  font-size: 23px;
}
.fonts {
  @media(max-width: 767px) {
    font-size: 12px;
  }
}
.custom-border {
  border-radius: 20px;
}
.add-cart-btn {
  &.disabled {
    background-color: #d9d9d9;
  }
  padding: 0;
  white-space: nowrap;
  transition: 0.5s;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  i {
    padding: auto;
    height: 100%;
    width: 30%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  P {
    width: 70%;
    height: 100%;
    justify-content: left;
    align-items: center;
    display: flex;
  }
}
.add-compare-btn {
  white-space: nowrap;
  transition: 0.5s;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 0;
  background-color: #fff;
  border: 1px solid #ff8100;
  color: #ff8100;
}
.custom-loader {
  display: inline-block;
  position: absolute;
  width: 52px;
  height: 52px;
}
.custom-loader div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 38px;
  height: 38px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: custom-loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.custom-loader div:nth-child(1) {
  animation-delay: -0.45s;
}
.custom-loader div:nth-child(2) {
  animation-delay: -0.3s;
}
.custom-loader div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes custom-loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.bg-cl-gray {
  color: #d9d9d9;
}
.bg-cl-gray {
  background-color: #d9d9d9;
}
</style>
